<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    :disabled-date="disabledStartDate"
                    v-model:value="state.params.start_date"
                    placeholder=" Tanggal Awal"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    :disabled-date="disabledEndDate"
                    v-model:value="state.params.end_date"
                    placeholder=" Tanggal Akhir"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:kabupaten="state.params.kabupaten"
                    v-model:area="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:value="state.params.product"
                    v-model:product="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:distributor="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:kabupaten="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterSegment
                    style="width:100%;"
                    :mode="null"
                    placeholder="Pilih Tipe Customer"
                    v-model:value="state.params.segment"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    style="width:100%;"
                    v-model:value="state.params.q"
                    placeholder="Cari ..."/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint.current"
                    :params="queryParams()"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint.current"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterSegment from '@/components/filter/FilterSegment'
import FilterProduct from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterRegional,
        FilterDistributor,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterSegment,
        FilterProduct,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'ID Trans Sales',
                    dataIndex: 'id_transaksi',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'tanggal_transaksi',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'id_toko',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'ID Wilyah toko',
                    dataIndex: 'id_wilayah_toko',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'alamat_toko',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'kab_toko',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'prov_toko',
                },
                {
                    title: 'ID Regional Toko',
                    dataIndex: 'id_reg_toko',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'reg_toko',
                },
                {
                    title: 'ID Area Toko',
                    dataIndex: 'id_area_toko',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'area_toko',
                },
                {
                    title: 'Brands',
                    dataIndex: 'brand',
                },
                {
                    title: 'ID Produk',
                    dataIndex: 'product_id',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'kode_produk',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'produk_name',
                },
                {
                    title: 'Kode MD Royalty',
                    dataIndex: 'kode_md_royalty',
                },
                {
                    title: 'Nama MD Royalty',
                    dataIndex: 'name_md_royalty',
                },
                {
                    title: 'Harga',
                    dataIndex: 'price',
                },
                {
                    title: 'ZAK Quantity',
                    dataIndex: 'zak_qty',
                },
                {
                    title: 'UOM 1',
                    dataIndex: 'uom1',
                },
                {
                    title: 'TON Quantity',
                    dataIndex: 'ton_qty',
                },
                {
                    title: 'UOM 2',
                    dataIndex: 'uom2',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'no_transaksi',
                },
                {
                    title: 'ID Distributor',
                    dataIndex: 'distri_id',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distri_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distri_name',
                },
                {
                    title: 'ID Gudang',
                    dataIndex: 'id_gudang',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'kode_gudang',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'nama_gudang',
                },
                {
                    title: 'ID Wilayah Gudang',
                    dataIndex: 'id_wilayah_gudang',
                },
                {
                    title: 'ID Regional Gudang',
                    dataIndex: 'id_reg_gudang',
                },
                {
                    title: 'Regional Gudang',
                    dataIndex: 'reg_gudang',
                },
                {
                    title: 'Provinsi Gudang',
                    dataIndex: 'prov_gudang',
                },
                {
                    title: 'ID Area Gudang',
                    dataIndex: 'id_area_gudang',
                },
                {
                    title: 'Area Gudang',
                    dataIndex: 'area_gudang',
                },
                {
                    title: 'Kabupaten Gudang',
                    dataIndex: 'kab_gudang',
                },
                {
                    title: 'Tipe Customer',
                    dataIndex: 'type',
                },
                {
                    title: 'ID Cluster',
                    dataIndex: 'id_cluster',
                },
                {
                    title: 'Cluster Pareto',
                    dataIndex: 'cluster_name',
                },
                {
                    title: 'ID SSM',
                    dataIndex: 'sales_ssm_id',
                },
                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ID ASM',
                    dataIndex: 'sales_sm_id',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'ID TSO',
                    dataIndex: 'sales_am_id',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Source System',
                    dataIndex: 'erp_name',
                },
                {
                    title: 'Created_at_Dist',
                    dataIndex: 'Created_at_Dist',
                },
            ],
            endpoint:{
                current: '/api/report/transaksi-toko-harian-tidak-diakui',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                area: [],
                provinsi: [],
                kabupaten: [],
                product: [],
                type: [],
                distributor: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()),
                page: 1,
                "per-page": 10,
            }),
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            disabledStartDate,
            disabledEndDate,
        }
    },
});
</script>
